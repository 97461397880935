exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apartments-comfort-js": () => import("./../../../src/pages/apartments/comfort.js" /* webpackChunkName: "component---src-pages-apartments-comfort-js" */),
  "component---src-pages-apartments-coral-js": () => import("./../../../src/pages/apartments/coral.js" /* webpackChunkName: "component---src-pages-apartments-coral-js" */),
  "component---src-pages-apartments-js": () => import("./../../../src/pages/apartments.js" /* webpackChunkName: "component---src-pages-apartments-js" */),
  "component---src-pages-apartments-marina-js": () => import("./../../../src/pages/apartments/marina.js" /* webpackChunkName: "component---src-pages-apartments-marina-js" */),
  "component---src-pages-apartments-perfect-js": () => import("./../../../src/pages/apartments/perfect.js" /* webpackChunkName: "component---src-pages-apartments-perfect-js" */),
  "component---src-pages-apartments-styles-js": () => import("./../../../src/pages/apartments/styles.js" /* webpackChunkName: "component---src-pages-apartments-styles-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-history-history-1-js": () => import("./../../../src/pages/history/history1.js" /* webpackChunkName: "component---src-pages-history-history-1-js" */),
  "component---src-pages-history-history-10-js": () => import("./../../../src/pages/history/history10.js" /* webpackChunkName: "component---src-pages-history-history-10-js" */),
  "component---src-pages-history-history-2-js": () => import("./../../../src/pages/history/history2.js" /* webpackChunkName: "component---src-pages-history-history-2-js" */),
  "component---src-pages-history-history-3-js": () => import("./../../../src/pages/history/history3.js" /* webpackChunkName: "component---src-pages-history-history-3-js" */),
  "component---src-pages-history-history-4-js": () => import("./../../../src/pages/history/history4.js" /* webpackChunkName: "component---src-pages-history-history-4-js" */),
  "component---src-pages-history-history-5-js": () => import("./../../../src/pages/history/history5.js" /* webpackChunkName: "component---src-pages-history-history-5-js" */),
  "component---src-pages-history-history-6-js": () => import("./../../../src/pages/history/history6.js" /* webpackChunkName: "component---src-pages-history-history-6-js" */),
  "component---src-pages-history-history-7-js": () => import("./../../../src/pages/history/history7.js" /* webpackChunkName: "component---src-pages-history-history-7-js" */),
  "component---src-pages-history-history-8-js": () => import("./../../../src/pages/history/history8.js" /* webpackChunkName: "component---src-pages-history-history-8-js" */),
  "component---src-pages-history-history-9-js": () => import("./../../../src/pages/history/history9.js" /* webpackChunkName: "component---src-pages-history-history-9-js" */),
  "component---src-pages-history-js": () => import("./../../../src/pages/history.js" /* webpackChunkName: "component---src-pages-history-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-prices-js": () => import("./../../../src/pages/prices.js" /* webpackChunkName: "component---src-pages-prices-js" */)
}

